/*------------------------------------*\
    #ABSTRACTS: TYPOGRAPHY
\*------------------------------------*/

/*
* Courtesy of https://blog.codepen.io/2016/10/31/fluid-type-blogs/ and https://codepen.io/MadeByMike/pen/VvwqvW
*/

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {

      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// Fonts
$font-system-rg: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-tiempos-regular: "TiemposTextWeb-Regular";
$font-tiempos-italic: "TiemposTextWeb-RegularItalic";
$font-system-mn: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;

$font-weight-regular: 400;
$font-weight-medium: 500;

// Variables
$line-height: 1.5; // Sets vertical rhythm (24px / 16px = 1.6)
$line-height-desktop: 1.7; // Sets vertical rhythm (29px / 19px = 1.52631578947)
$baseline: 1.7rem;

// Fluid type settings
$min_width: 320px;
$max_width: 675px;
$min_font: 14px;
$max_font: 16px;

// Modular scale settings
$mod_1: 1.2; // mobile
$mod_2: 1.667; // desktop
