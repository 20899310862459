/*------------------------------------*\
    #COMPONENTS: IMAGE
\*------------------------------------*/

/**
 * Component Block
 */

 .Image {
  @include margin-top(2);

  & + p {
    @include margin-top(1);
  }

  figcaption {
    @include margin-top(0.25);
  }
}

@include breakpoint(sm) {
   .Image {
    margin: 0;
  }
}

/* Lazyload animation */
.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}
