/*------------------------------------*\
    #LAYOUTS: PAGE
\*------------------------------------*/


/**
 * Component Block
 */

.Page {
  max-width: 840px;

  p {
    max-width: 440px;
  }

  a {
    text-decoration: underline;
  }
}

.Page .Image {
  display: block;
  @include margin-top(2);
}

@include breakpoint(ml) {
  .Page .Image {
    float: right;
    max-width: 560px;
    padding: 0 40px 40px 40px;
    margin-top: 0;
  }
}

@include breakpoint(lg) {
  .Page .Image {
    margin-bottom: 64px;
    margin-right: 7%;
    max-width: 560px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 260px;
  }
}

p + p {
  @include margin-top(1);
}

.Page-title {
  @include margin-bottom(2);
}
