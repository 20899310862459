/*------------------------------------*\
    #COMPONENTS: MOBILE MENU
\*------------------------------------*/

/**
 * Component Block
 */

.Mobile-nav {
  background: $color-darker;
  display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
  height: 10000px;
  padding: 7%;
  z-index: 3; /* Make sure the z-index is lower than the .page-container */

  &.is-open {
    display: block;
  }

  @include breakpoint(sm) {
    &.is-open {
      display: none;
    }
  }
}

.Mobile-link {
  color: $color-white;
  display: block;
  font-family: $font-system-rg;
  max-width: 170px;
  margin: 20px 0;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: $color-white;
  }
}
