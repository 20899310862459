/*------------------------------------*\
    #COMPONENTS: FOOTER
\*------------------------------------*/

/**
 * Component Block
 */

.Footer {
  max-width: 440px;
}
