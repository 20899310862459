/*------------------------------------*\
    #COMPONENTS: HAMBURGER
\*------------------------------------*/

/**
 * Component Block
 */

.Hamburger {
  display: block;
  float: right;
}

@include breakpoint(md) {
  .Hamburger {
    display: none;
  }
}

.Hamburger .line {
  width: 24px;
  height: 2px;
  background-color: $color-dark;
  display: block;
  margin: 4px auto;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Hamburger:hover {
  cursor: pointer;
}


#Hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

#Hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

#Hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
