/*------------------------------------*\
    #COMPONENTS: HEADER
\*------------------------------------*/

/**
 * Component Block
 */

.Header {
  @include margin-bottom(3);
}

.Header-title {
  @include margin-bottom(1);

  a {
    font-size: 160%;

    &:hover {
      text-decoration: none;
    }
  }
}

.Header-nav {
  display: none;
}

@include breakpoint(md) {
  .Header-nav {
    display: block;
    font-family: $font-system-rg;
    font-size: 80%;
    line-height: $line-height * 1.2;
    text-align: right;

    a {
      display: inline-block;
      padding: 0 10px;
    }
  }
}

.Header-link {
  display: block;
}
