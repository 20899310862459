/*------------------------------------*\
    #BASE: LINKS
\*------------------------------------*/

a {
  color: $color-link;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: darken($color-link, 15%);
    text-decoration: line-through;
  }

  &:visited {
    border-bottom: 1px solid $color-link;
    text-decoration: none;
  }

  &:before {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out
  }
}
