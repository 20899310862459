/*------------------------------------*\
    #LAYOUTS: MASONRY
\*------------------------------------*/

/**
* Component Block
*/

.Masonry {
  column-gap: 4em;
}

.Masonry-item {
  background: #fff;
  margin: 0 0 2em;
}

/* Masonry on small screens */
@include breakpoint(sm) {
  .Masonry {
    column-count: 1;
    column-gap: 3em;
  }

  .Masonry-item {
    display: inline-block;
    margin: 0 0 3em;
  }
}

/* Masonry on medium-sized screens */
@include breakpoint(md) {
  .Masonry {
    column-count: 2;
    column-gap: 3em;
  }

  .Masonry-item {
    display: inline-block;
    margin: 0 0 3em;
  }
}

/* Masonry on large screens */
@include breakpoint(lg) {
  .Masonry {
    column-count: 3;
    column-gap: 3em;
  }

  .Masonry-item {
    display: inline-block;
    margin: 0 0 3em;
  }
}

