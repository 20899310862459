/*------------------------------------*\
    #COMPONENTS: HERO
\*------------------------------------*/

/**
 * Component Block
 */

.Hero {
  text-align: center;
  @include margin-bottom(3);
}

.Hero-title {
  margin: 0 auto;
  max-width: 400px;

}
