/*------------------------------------*\
    #ABSTRACTS: COLORS
\*------------------------------------*/

$color-white: #fff !default;
$color-darker: #1a1a1a !default;
$color-dark: #2f2f2f !default;
$color-body: #333 !default;
$color-light: #555 !default;
$color-lighter: #888 !default;
$color-lightest: #f5f5f5 !default;
$color-link: #444 !default;
