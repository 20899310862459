/*------------------------------------*\
    #LAYOUTS: POST
\*------------------------------------*/


/**
 * Component Block
 */

//.Posts {}

.Post {
  position: relative;
}

.Post-image:hover {
  cursor: pointer;
}

.Post-image:hover .Post-meta {
  opacity: 0;
}

.Post-image:hover .Post-meta {
	opacity: 1;
}

.Post-meta {
  background: $color-darker;
  cursor: default;
  opacity: 0;
  padding: 20px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  width: 260px;
  z-index: 3;
  transition: all 0s ease-out;

  p {
    color: $color-white;
    font-family: $font-tiempos-regular;
    font-size: 80%;
    line-height: $line-height-desktop /1.2;
    margin: 0;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@include breakpoint(sm) {
  .Post-meta {
    padding: 20px 20px;
    max-width: 400px;
  }
}
