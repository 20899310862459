/*------------------------------------*\
    #ABSTRACTS: FONTS
\*------------------------------------*/

// Import font
@font-face {
  font-family: 'TiemposTextWeb-Regular';
  src: url('../../fonts/TiemposTextWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/TiemposTextWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/TiemposTextWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/TiemposTextWeb-Regular.woff') format('woff'), /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TiemposTextWeb-RegularItalic';
  src: url('../../fonts/TiemposTextWeb-RegularItalic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/TiemposTextWeb-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/TiemposTextWeb-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/TiemposTextWeb-RegularItalic.woff') format('woff'), /* Pretty Modern Browsers */
}
